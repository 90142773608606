.page-wrapper {
  padding: 18px;
}
.content {
  display: flex;
  gap: 18px;
  justify-content: center;
  background-color: #ffffff;
  height: calc(100vh - 123px);
  .block {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding: 18px 0;
      overflow: auto;
      .MuiFormControl-root {
        max-height: calc(100vh - 291px);
      }
    }
}

.loader{
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px) brightness(0.2);
  z-index: 9999;
}

.group-button {
  display: flex;
  justify-content: space-between !important;
  gap: 18px !important;
}

textarea {
  resize: none;
  height: 1449px;
  max-height: calc(100vh - 312px);
  overflow: auto !important;
}

.login {
    height: calc(100vh - 62px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 18px;
      padding: 30px 40px;
      border: 1px solid #e0e0e0;
      height: 400px;
      max-width: 500px;
      width: 50%;
    }
}

.logout {
  display: flex;
  justify-content: end;
  &:hover {
    cursor: pointer;
    color: red;
  }
}